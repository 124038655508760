.add-photo {
  margin: 30px;
  border: 0px dotted grey;
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo-container {
  flex: 0 25%;
}

.dot {
  border: solid grey;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  -webkit-box-shadow: 0 3px 5px rgba(0,0,0,0.3);
  box-shadow: 0 3px 5px rgba(0,0,0,0.3);
  cursor: pointer;
}

.dot:hover {
  background-color: var(--accent-light-color-darken);
  color: white;
}

.wrap-photo-print-container{
  width: 100%;
  height: 100;
  &.toolbar-opened{
    padding-right: calc(var(--toolbar-view-width)/2);
    padding-left: calc(var(--toolbar-view-width)/2);
    .photo-print-container{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}

.mobile{
  .wrap-photo-print-container {
    &.toolbar-opened {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
}

.photo-print-container {
  display: flex;
  flex-wrap: wrap;
  padding-right: calc(var(--toolbar-view-width)/3);
  padding-left: calc(var(--toolbar-view-width)/3);
  width: 100%;
  max-height: 100%;
  overflow-y: scroll;
}

.edit-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.photo-count {
  position: relative;
  padding: 17px 17px 0px;
  padding-bottom: 15px;
  color: #606060;
  // margin-left: calc(var(--toolbar-view-width)/2);
  font-weight: bold;
  z-index: 5;
  width: 100%;
  background-color: var(--bg);
  background: linear-gradient(0deg, rgba(237,240,242,0) 0%, rgba(237,240,242,1) 100%);
  text-shadow: 0px 0px 5px #fff;
}

.mobile {
  .photo-count {
    padding: 13px 13px 0px;
    margin-left: 0px;
  }

  .photo-container {
    flex: 0 1 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .loading-wrapper {
    position: fixed;
  }
  .photo-print-container {
    padding-right: 15px;
    padding-left: 15px;
  }

  .wrap-photo-print-container{
    &.toolbar-opened{
      padding-right: 0px;
      padding-left: 0px;
      .photo-print-container{
        padding-left: 0px;
        padding-right: 0px;
      }
    }
  }

}