.progress {
  position: relative;
  background: #e3e6e8;
  border-radius: 4px;
  height: 7px;
  overflow: hidden;
}

.progress > span {
  display: block;
  height: 100%;
  border-radius: 4px;
  background-color: #383838;
  position: relative;
  overflow: hidden;
}
