.mobile .toolbar.background {
  --footer-shift: calc(var(--max-footer-shift) - 170px);
}

.colors-container {
  .color-option-container.color-picker{
    padding: 5px;
    width: 100%;
    height: 100%;
  }
  width: 100%;
  height: 100%;
}

.colors-container {
  .go-back-button {
    border: none;
    padding-left: 20px;
  }
}

.color-option-container > svg {
  display: block;
}