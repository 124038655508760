.zoom-slider-container {
  flex: 2;
  margin-right: 7%;
}
.zoom-text {
  color: var(--background-grey);
  font-weight: bold;
  flex: 1;
}
.zoom-container {
  text-align: center;
  display: flex;
  max-width: 400px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  background: rgb(241, 242, 242);
  padding: 13px;
  border-radius: 5px;
}
.mobile .zoom-container {
  flex-direction: column-reverse;
  user-select: none;
  border-radius: unset;
  max-width: unset;
}
.mobile .zoom-slider-container {
  margin-left: 15%;
  margin-right: 15%;
}
.mobile .zoom-text {
  margin-top: 15px;
  font-size: 22px;
}
