.grid-back-container {
  display: flex;
  flex-direction: column;
  // height: 100%;
}

.grid-container {
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

  
.grid {
  display: flex;
  flex-wrap: wrap;
  margin-left: -5px;
  margin-right: -5px;
  flex-direction: row;
  .grid-item {
    display: inline-flex;
    //Instead of the line below you could use @include box-sizing($bs)
    box-sizing: border-box;
    height: 0;
    position: relative;
    vertical-align: top;
    .grid-item-inner {
      display: flex;
      justify-content: center;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      padding: 5px;
      //Instead of the line below you could use @include box-sizing($bs)
      box-sizing: border-box;
      &.folder {
        top: -5px;
        cursor: pointer;
        font-size: 14px;
      }
      .progress-bar-container {
        background-color: white;
        height: 30px;
      }
      &.back-button {
        padding-left: 10px;
        button {
          width: 100%;
          padding: 3px;
          height: 100%;
          display: flex;
          border: none;
          > * {
            margin: auto;
            &:first-child {
              margin-right: 0 !important;
            }
            &:last-child {
              margin-left: 5px !important;
            }
          }
        }
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: relative;
      cursor: pointer;
    }
    &.back-button {
      padding-top: calc(130px - 10px) !important;
    }
  }
}

.mobile {
  .grid-container {
    margin-bottom: 0;
    height: 100%;
    overflow-x: hidden;
  }
  .grid {
    justify-content: center;
    height: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    overflow: scroll;
    margin-bottom: 0;
    flex-direction: column;
    .grid-item {
      min-width: 27% !important;
      width: 25% !important;
      padding-top: 25% !important;
      &.back-button {
        padding-top: 25% !important;
        min-width: 115px;
        min-height: 50px;
      }
    }
  }
}

.scroll-container{
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.scroll-to-top{
  background: linear-gradient(90deg, white 17%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  font-size: 30px;
  width: 40px;
  height: 100%;
  z-index: 10;
  align-items: center;
  display: flex;
}

.scroll-to-end{
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, white 83%);  
  position: absolute;
  width: 40px;
  height: 100%;
  font-size: 30px;
  right: 0px;
  z-index: 10;
  display: flex;
  align-items: center;
}
