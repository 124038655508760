.main-layout {
  min-height: 100%;
  height: 100%;
  background: var(--bg);
}
.main-layout.mobile {
  overflow: hidden;
}
.main-layout .content-wrapper {
  padding-top: var(--navbar-height);
  display: flex;
  /* min-height: calc(100vh - var(--navbar-height)); */
  min-height: 100%;
  height: 100%;
  overflow: auto;
}
.main-layout.mobile .content-wrapper > * {
  padding-bottom: var(--footer-height);
}

.main-layout .content-wrapper .content {
  overflow: hidden;
  margin: 0 auto;
  /* box-shadow: inset 4px 0 5px 0px rgba(0,0,0,0.1); */
  position: relative;
  width: 100%;
}

.main-layout .content-wrapper .width-without-toolbar {
  max-width: calc(100vw - var(--toolbar-width));
}

.main-layout.mobile .content-wrapper .content {
  max-width: 100vw;
  /* box-shadow: inset 4px 0 5px 0px rgba(0,0,0,0.1); */
  position: relative;
  width: 100%;
  overflow: hidden;
}

/* layout content end */

/* Nav bar */
.main-layout .navbar {
  position: fixed;
  width: 100%;
  height: var(--navbar-height);
  display: flex;
  align-items: center;
  padding: 0 var(--navbar-padding-horizontal);
  padding-right: 10px;
  background: var(--navbar-bg);
  background: linear-gradient(90deg, rgba(40,33,84,1) 5%, rgba(70,61,126,1) 100%);
  z-index: var(--z-navbar);
}

.main-layout .navbar .navbar-right {
  display: flex;
  margin-left: auto;
  text-align: right;
}
.main-layout.mobile .navbar .navbar-right .design-id {
  margin-left: 5px;
  margin-right: 5px;
}
@media (max-width: 390px) {
  .main-layout.mobile .navbar .navbar-right > button {
    font-size: 14px;
    padding-right: 10px;
    padding-left: 10px;
  }
}
.main-layout .navbar .design-id {
  color: white;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: 30px;
  font-size: 0.85em;
}
.main-layout .navbar .download-button {
  background-color: Transparent;
  margin-right: 10px;
  margin-top: 7px;
  margin-bottom: 7px;
}

.main-layout .navbar .order-button {
  border: 1px solid;
  display: flex;
  color: white;
  background-color: var(--accent-light-color);
  border-color: var(--accent-light-color);
  margin-top: 7px;
  margin-bottom: 7px;
}

.main-layout .navbar .order-button:hover {
  background-color: var(--accent-light-color-darken);
  border-color: var(--accent-light-color-darken);
}

button:disabled {
  color: #949494 !important;
  background-color: rgb(113 105 162) !important;
  border-color: rgb(113 105 162) !important;
}

.main-layout .navbar .order-button > div {
  margin-top: auto;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: auto;
}
@media (max-width: 500px) {
  .main-layout .navbar .order-button > svg {
    margin-right: 2px !important;
  }
  .main-layout .navbar .order-button > div {
    display: none;
  }
}

.main-layout .navbar .logo {
  height: 30px;
}
/* Nav bar end */

.mobile .hide-on-mobile {
  display: none;
}

.navbar-admin {
  height: 34px;
  margin-left: 10px;
  font-size: 22px;
  color: white;
}