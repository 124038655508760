.mobile .toolbar.layouts {
  --footer-shift: calc(var(--max-footer-shift) - 270px);
}

.layouts-container {
  flex-direction: column;
  display: flex;
  width: 100%;
  height: 100%;
}

.layouts-container .grid-back-container {
  height: 40%;
}

.no-layout-switch .grid-back-container {
  height: 100%;
}

.layouts-container .layout-option-container {
  padding: 15px;
  width: 100%;
  height: 100%;
}
.layout-option-container > svg {
  display: block;
}
