.multi-option-button-container {
  display: flex;
  width: 100%;
  padding: 0 10% 0 10%;
  --button-border-color: hsl(0, 0%, 84%);
  --selected-button-color: #6c7075;
}

.multi-option-button {
  height: 45px;
  border: 1px solid;
  border-color: var(--button-border-color);
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
  border-right: 0;
  cursor: pointer;
  color: var(--selected-button-color);
  transition: 0.25s;
}
.mobile .multi-option-button {
  height: 33px;
}

.multi-option-button.left {
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
}
.multi-option-button.right {
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  border-right: 1px solid;
  border-color: var(--button-border-color);
}
.multi-option-button.selected {
  background: var(--selected-button-color);
  color: white;
}
.multi-option-button:not(.selected):hover {
  background: #e5e7e7;
}