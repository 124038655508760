.edge-tab {
  width: 100%;
  /*overflow: scroll;*/
}
.edge-tab .vertical-select-option small,
.edge-tab .vertical-select-option label {
  font-weight: bold;
}
.mobile .edge-tab {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.mobile .edge-tab > div {
  margin-top: auto;
  margin-bottom: auto;
}
.mobile .edge-tab > div:last-child {
  margin-bottom: 0;
}
.mobile .edge-tab .vertical-select-container {
  display: flex;
}
.mobile .edge-tab .vertical-select-option {
  border: none;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
}
.mobile .edge-tab .vertical-select-option small,
.mobile .edge-tab h2 {
  display: none;
}
.mobile .edge-tab .vertical-select-option-content {
  /* display:flex; */
  flex-direction: column;
}
.mobile .edge-tab .vertical-select-option-content img {
  margin: auto;
}
.mobile .edge-tab .vertical-select-option-content.checked::before,
.mobile .edge-tab .vertical-select-option-content.checked::after {
  bottom: 20px;
  right: 0px;
}

.mobile .toolbar.edges {
  --footer-shift: calc(var(--max-footer-shift) - 170px);
}

@media (max-height: 610px) {
  .mobile .edge-tab .vertical-select-option-content img {
    width: 50px;
    height: 50px;
  }
  .mobile .toolbar.edges {
    --footer-shift: calc(var(--max-footer-shift) - 170px + 30px);
  }
}
@media (max-width: 310px) {
  .mobile .edge-tab .vertical-select-option-content label {
    font-size: 13px;
  }
}
