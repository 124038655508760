.mobile {
  .toolbar-text > div{
    margin: auto 5px !important;
  }
  .break {
    flex-basis: 100% !important;
  }
  .toolbar-text{
    height: calc(2 * var(--footer-height)) !important;
    display: flex !important;
    flex-wrap: wrap !important;
    .done-button{
      min-width: 20px;
    }
    .text-color-button{
      min-width: 20px;
    }
    .font-size-button {
      min-width: 60px;
    }
  }
  .color-picker-container{
    margin-left: 0%;
  }
  
}

.toolbar-text > div{
  margin: auto 10px !important;
}
.toolbar-text{
  overflow: inherit !important;
  font-size: 90%;
  svg {
    margin-right: 0px !important;
  }
  svg.left-action {
    margin-right: 5px !important;
  }
  .highlight {
    min-width: 20px;
    margin: 5px 5px !important;
    padding: 7px;
    background: #f6f6f6;
    border-radius: 7px;
  }

  .number-input__indicator {
    padding: 0px !important;
  }
  .number-input__control {
    min-height: 33px;
  }
  .text-input__indicator {
    padding: 2px !important;
  }
  .text-input__control {
    min-height: 33px;
  }
  .text-input__indicator-separator {
    display: none;
  }
  .select-number{
    min-width: 60px;
    display: inline-block;
  }
  .text-input{
    width: 100%;
    max-width: 130px;
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    padding: 0 10px;
    font-size: 17px;
    height: 33px;
    color: hsl(0,0%,20%);
  }

  .done-button{
    min-width: 40px;
    margin-left: auto !important;
    border-right: 1px solid #ccc;
    padding-right: 5px;
  }

  .text-button {
    min-width: 150px;
  }

  .bold-button {
    min-width: 20px;
    padding: 7px;
    margin: 5px 5px !important;
    svg {
      margin-right: 0px !important;
    }
  }

  .italic-button {
    min-width: 20px;
    padding: 7px;
    margin: 5px 5px !important;
    svg {
      margin-right: 0px !important;
    }
  }

  .font-size-button {
    min-width: 70px;
  }

  .delete-button {
    min-width: 60px;
    margin-right: auto !important;
  }
}

.color-picker-container{
  margin-left: 60%;
}

.colour-selected {
  height: 3px;
  width: 20px;
  border-radius: 19px;
  margin: 2px auto 0;
}

.text-align-buttons {
  min-width: 90px;
  color: black;
  .multi-option-button-container{
    padding: 0px;
    .left {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .right {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .multi-option-button{
      height: 33px;
    }
  }
}

.break {
  width: 0 !important;
  margin: 0 !important;
}