.text-input-field {
  margin: auto;
  background: #0000;
  border: none;
  position: absolute;
  font-size: 30px;
  overflow: hidden;
  white-space:pre;
  padding: 0;
  cursor: text;
  &:focus{
    outline: none
  }
}
.text-input-field-container{
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  // background: #fff3;
}

.text-input-container{
  &:focus{
    outline: none;
  }
}