.switch {
  display: flex;
  justify-content: center;
  align-items: center;
  label {
    margin-right: 5px;
    font-weight: bold;
    font-size: 1.2em;
  }
  .react-toggle--checked{
    &:hover{
      .react-toggle-track {
        background-color: var(--accent-light-color-darken); 
        
      }
      .react-toggle-thumb {
        border-color: var(--accent-light-color-darken);
      }  
    }
    .react-toggle-track {
      background-color: var(--accent-light-color); 
      
    }
    .react-toggle-thumb {
      border-color: var(--accent-light-color);
    }
  }
}