.vertical-select-container {
  margin: 0px 10px 0px 10px;
}
.vertical-select-option {
  border-top: 1px solid;
  border-color: var(--delimiter-color);
  padding: 15px 0px 15px 0px;
  cursor: pointer;
}
.vertical-select-option * {
  cursor: pointer;
}
.vertical-select-option small {
  color: var(--background-grey);
  /* border: 1px solid transparent; */
  white-space: pre-wrap;
}
.vertical-select-option img {
  height: 80px;
  width: 80px;
  margin-left: -10px;
  margin-right: 10px;
}
.vertical-select-option:last-child {
  border-bottom: 1px solid;
  border-color: var(--delimiter-color);
}
.only-inner-delimiters .vertical-select-option:last-child {
  border-bottom: none;
}
.only-inner-delimiters .vertical-select-option:first-child {
  border-top: none;
}
.vertical-select-option-content {
  margin: 0px 10px 0px 10px;
  position: relative;
  display: flex;
}
.vertical-select-option-content.--check-right {
  --check-right: -10px;
}
.vertical-select-option-content.checked::before,
.vertical-select-option-content.checked::after {
  bottom: 0px;
}

.vertical-select-option-content.checked .small,
.vertical-select-option-content .small.preserve-padding-for-check {
  margin-right: calc(var(--check-circle-size) + var(--check-right));
}

.vertical-select-option-content.empty-description::before,
.vertical-select-option-content.empty-description::after {
  bottom: -8px;
}
