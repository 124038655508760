.photo-library {
  height: 100%;
  display: flex;
  flex-direction: row;
  background: white;

  .no-photos {
    font-size: 1em;
    color: gray;
    margin-top: 1em;
    text-align: center;

    span {
      font-size: 0.9em;
      margin-left: 10px;
    }
  }

  &.compact {
    flex-direction: column;
    width: 100%;
    .buttons-container {
      margin: 0 auto 15px auto;
    }
  }
  &:not(.compact) .buttons-container {
    flex-shrink: 0;
    flex-basis: 20%;
    min-width: 200px;
    max-width: 500px;
    border-right: 1px solid var(--toolbar-bg);
    margin-right: 40px;
    padding-right: 10px;
    height: 200px;
  }
  .folder {
    flex-direction: column;
    > svg {
      height: 75px;
      width: 100% !important;
      max-width: 60px;
      margin: 0 auto;
      max-height: 100%;
    }
    > span {
      margin: -5px 0;
      text-align: center;
      font-size: 13px;
    }
  }
  .progress-bar-container {
    position: absolute;
    width: 100%;
    padding: 5px;
    left: 0;
    bottom: 0;
  }
  .progress-text {
    background-color: white;
    text-align: center;
  }
  .progress-bar {
    margin: 5px;
  }
  
  .LinesEllipsis {
    white-space: pre-wrap;
    //Instead of the line below you could use @include word-break($value)
    word-break: break-all;
    text-align: center;
  }
}

.mobile {
  .photo-library {
    height: 100%;
    flex-direction: column;
    &:not(.compact) .buttons-container {
      flex-basis: 0;
      border-right: none;
    }

    .no-photos {
      font-size: 1.2em;
    }
  }
  
  .grid {
    .grid-item {
      &.folder {
        width: 19% !important;
      }
    }
  }
  .grid-back-container {
    height: 100%;
  }
  .toolbar-view .photo-library .buttons-container {
    margin-left: 5px;
    margin-top: calc(var(--footer-title-height) * -0.8);
    margin-bottom: 0;
    button {
      //Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
      transform: scale(0.9);
      //Instead of the line below you could use @include transform-origin($origin-x, $origin-y, $origin-z, $only3d)
      transform-origin: top left;
    }
  }
}



.go-back {
  margin-right: auto;
}
.toolbar-view .go-back {
  margin-left: auto;
}