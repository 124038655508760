.button {
  background-color: var(--accent-color);
  border: none;
  color: white;
  padding: 8px 20px 8px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  outline: none;
  cursor: pointer;
  font-weight: 600;
  transition: 0.25s;
}
.button:hover {
  background-color: var(--accent-light-color);
}
.button:active {
  background-color: var(--accent-dark-color);
}
.button.rounded {
  border-radius: 5px;
}

.button.outline {
  background-color: white;
  color: black;
  border: 2px solid black;
}

.button.accent-dark-color {
  background-color: var(--accent-dark-color);
}

.button.accent-dark-color.disabled {
  filter: brightness(50%);
  cursor: not-allowed;
}

.button.lg-size {
  padding: 11px 40px;
  font-size: 20px;
}
.button.xl-size {
  padding: 14px 76px;
  font-size: 22px;
  /* font-weight: bold; */
}
.button.md-text {
  font-size: 15px;
}

.button.round.xl-size {
  border-radius: 11px;
}

.button.azure-color {
  background-color: var(--accent-light-color);
  border-color: var(--accent-light-color);
}

.button.azure-color:hover {
  background-color: var(--accent-light-color-darken);
  border-color: var(--accent-light-color-darken);
}

.button.secondary-color {
  background-color: white;
  color: var(--accent-dark-color);
  border-color: var(--accent-dark-color);
}

.button.secondary-color:hover {
  background-color: var(--accent-dark-color);
  color: white;
  border-color: var(--accent-dark-color);
}
