.photo {
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.icon-container {
  display: flex;
  margin-right: auto;
  margin-left: auto;
  color: #8a8d90;
  padding: 10px 0px;
}

.rotate {
  margin-right: auto;
  cursor: pointer;
}
.rotate:hover {
  color: var(--accent-light-color);
}

.delete {
  margin-left: auto;
  cursor: pointer;
}
.delete:hover {
  color: var(--accent-light-color);
}

.crop {
  border-radius: 0px 10px 0px 0px;
  padding: 6px 8px;
  background-color: #80808094;
  position: absolute;
  bottom: 0px;
  left: 0px;
  color: white;
  cursor: pointer;
}
.crop:hover {
  color: var(--accent-light-color);
}

.quantity {
  text-align: center;
  background-color: transparent;
  border-radius: 6px;
  font-size: 95%;
  padding: 2px 3px;
  width: 25px;
  margin-left: 5px;
  margin-right: 5px;
}

.change-quantity {
  color: #362e68;
  cursor: pointer;
}
.change-quantity:hover {
  color: var(--accent-light-color);
}