.mobile .toolbar.customize {
  --footer-shift: calc(var(--max-footer-shift) - 200px);
}

.customize-container {
  .color-option-container.color-picker{
    padding: 5px;
    width: 100%;
    height: 100%;
  }
  .customize-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    button {
      padding-left: 8px;
      padding-right: 8px;
      width: 48% !important;
      border: 1px solid;
      min-height: 75px;
      text-align: center;
    }
  }
  width: 100%;
  height: 100%;
}

.color-option-container > svg {
  display: block;
}
.colors-container {
  .grid-back-container {
    height: 60%;
  }
}

.mobile {
  .customize-container {
    .customize-button-container {
      flex-direction: row;
    }
  }
}