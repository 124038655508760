.borders-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.mobile .borders-container {
  flex-direction: column-reverse;
}
.border-button {
  margin-top: 10px;
  margin-bottom: 35px;
}
.mobile .border-button {
  /* margin-bottom: 5px; */
  margin-bottom: 0px;
}

.mobile .toolbar.borders {
  --footer-shift: calc(var(--max-footer-shift) - 170px);
}