.sizes-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.mobile .sizes-container {
  /*flex-direction: column-reverse;*/
}
.orientation-button {
  margin-top: 10px;
  margin-bottom: 35px;
}
.mobile .orientation-button {
  /* margin-bottom: 5px; */
  margin-bottom: 0px;
}
.sizes-select {
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.sizes-select .grid-select-option label,
.sizes-select .grid-select-option small {
  font-weight: bold;
  cursor: pointer;
}

.grid-select-option small {
  color: var(--background-grey);
  white-space: pre-wrap;
}

/* @media (max-height: 610px) { */
.mobile .toolbar.sizes {
  --footer-shift: calc(var(--max-footer-shift) - 185px + 30px);
}
/* } */

.sizes-select .grid .grid-item .grid-item-inner {
  cursor: pointer;
}

.sizes-select .grid-container .grid {
  margin-left: 0px;
  margin-right: 0px;
}

.sizes-select .grid-container .grid .grid-item {
  width: 48% !important;
  padding-top: 28% !important;
  border: 1px solid #e4e4e4;
  margin-right: 2%;
  margin-bottom: 2%;
  border-radius: 5px;
}

.sizes-select .grid-container .grid .grid-item:hover {
  border: 1px solid #a7a7a7;
}

.mobile .sizes-select .grid-container .grid .grid-item {
  width: 31% !important;
  padding-top: 20% !important;
  margin-right: 2%;
  margin-bottom: 0;
}


.sizes-select .grid-container .grid .grid-item .grid-select-option {
  width: 100%;
}