.add-photo {
  margin: 50px;

  border: 4px dotted grey;
  border-radius: 10px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  border: grey solid;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
}