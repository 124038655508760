.display-table {
  display: table; 
  background-color: white;
}
.display-table > div { 
  display: table-row; 
}
.display-table > div > div { 
  display: table-cell;
  padding: 8px;
}

.toolbar.edit {
  .toolbar-view-container{
    display: flex;
    user-select: none;
    flex-direction: column;
    position: fixed;
    top: 0px;
    width: 100%;
    width: 100%;
    left: 0px;
    .display-table {
      position: unset;
      z-index: 11;
      user-select: none;
      box-shadow: inset 0 0 0 1px #c6c6c6;
      width: 50%;
      margin-left: auto;
      margin-right: auto;
      left: 0px;
      padding: 7px;
      .value-slider {
        text-align: center;
        background-color: white;
        background-color: -moz-field;
        border-style: solid;
        border-width: 1px;
        border-color: gray;
        border-radius: 6px;
        font-size: 90%;
        padding: 2px 3px;
        width: 40px;
      }
      .slider-container {
        width: 100%;
        user-select: none;
      }
    }

    .toolbar-view {
      padding: 0;
      position: unset;
      height: 44px;
      z-index: 12;
      width: 100%;
      > div {
        margin-top: auto;
        margin-bottom: auto;
        margin: 10px;
        transition: 0.25s;
        &:first-child {
          margin-left: auto;
        }
        &:hover {
          cursor: pointer;
          color: var(--accent-light-color);
        }
        &:last-child {
          margin-right: auto;
        }
        svg {
          margin-right: 5px;
        }
      }
    }
  }
}
.mobile .toolbar.edit {
  .toolbar-view-container {
    top: unset;
    bottom: 0;
    position: fixed;
    //box-shadow: inset 0 0 0 2px #c6c6c6;
    .display-table {
      width: 100%;  
    }
    .toolbar-view {
      height: var(--footer-height);
      z-index: 11;
      > div {
        margin: auto;
        //fix in narrow screen, center of two columns is shifted
        flex: 1;
        text-align: center;
      }
      svg {
        margin-right: 0px;
      }
    }
  }
}

.edit-footer-quality {
  padding: 4px;
  text-align: center;
  font-size: 14px;
}