/* Footer */
.mobile .toolbar .phantom-div {
  display: block;
  height: var(--footer-height);
  width: 100%;
}
.mobile .toolbar-inner {
  background-color: var(--footer-bg);
  font-size: 20px;
  color: white;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  height: var(--footer-height);
  width: 100%;
  display: flex;
  z-index: 10;
}
.mobile .toolbar-inner ul {
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
  width: 100%;
}
.mobile .toolbar-inner ul li {
  flex: 1;
}
.mobile .toolbar {
  --max-footer-shift: calc(calc(var(--vh, 1vh) * 100) - var(--navbar-height) - var(--footer-title-height) - var(--footer-height)); /* when 'footer menu title top' is just below the 'top of footer bar'*/
  --footer-shift: calc(var(--vh, 1vh) * 50); /* transparent height from 'bottom of navbar' to 'top of opened footer`s title' */
  --footer-title-height: calc(var(--vh, 1vh) * 5.5);
  --top: calc(var(--navbar-height) + var(--footer-shift) + var(--footer-title-height));
}
.mobile .toolbar .toolbar-view {
  position: absolute;
  left: 0;
  top: var(--top);
  height: calc(100% - var(--top) - var(--footer-height) + 1px); /* 1px is important to remove 1px 'border'*/
  width: 100%;
  z-index: 10;
  background-color: white;
  /* overflow: hidden; */
  box-sizing: border-box;
  box-shadow: unset;
  padding-left: 5px;
}
.mobile .toolbar .toolbar-view.photos {
  overflow: visible;
}
.mobile .toolbar .toolbar-view-header.photos > img {
  margin-bottom: -5px;
  margin-top: 15px;
}
.mobile .toolbar .toolbar-view.hide {
  display: none;
}

.mobile footer .toolbar ul button {
  color: #555;
  padding: 0;
}

.mobile .toolbar ul li {
  padding: 0;
}
.mobile .toolbar ul button .button-content {
  transform: scale(0.8);
}
.mobile .toolbar ul button {
  height: 100%;
}
.mobile .toolbar ul button .toolbar-icon {
  margin-bottom: 2px;
}
.mobile .toolbar ul button:hover:not(.active) {
  height: 100%;
  background-color: transparent;
  color: #555;
}
.mobile .toolbar ul button.active {
  height: 100%;
  background-color: unset;
}
.mobile footer .toolbar ul button.active::before {
  content: "";
  border: 1px solid #d1d1d1;
  background-color: white;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  border-top: 1px solid transparent;
  position: absolute;
  min-width: 54px;
  width: 17vw;
  top: 0;
  height: calc(100% - 8px);
}
.mobile .toolbar ul button:not(.active) {
  border: 1px solid transparent;
}

.mobile .toolbar-view-header {
  position: absolute;
  top: calc(var(--top) - var(--footer-title-height) + 1px);
  height: var(--footer-title-height);
  box-shadow: 4px 0 5px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  background-color: white;
  display: flex;
}
.mobile .toolbar-view-header span {
  margin: auto auto auto 10px;
  font-size: larger;
}
.mobile .toolbar-view-header img {
  height: auto;
  margin: 5px 10px 5px auto;
}
.mobile .toolbar-view-header.photos span {
  display: none;
}

/* Footer end */

.done-button{
  border-right: 1px solid #ccc;
  padding-right: 5px;
}