.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  text-align: center;
}

.modal-header {
  text-align: center;
  font-weight: normal;
  /*display: flex;*/
  text-align: center;
  margin-top: 0px;
  width: 100%;
  font-size: 26px;
}
.modal.order .modal-header {
  margin-top: -6px;
}

.buttons {
  text-align: center;
}

.buttons > * {
  margin: "2px";
  padding: 11px 20px;
}

.modal-header-icon {
  margin-left: auto;
  margin-right: 12px;
}

.modal-header-text {
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 0.9em;
}

.modal-text {
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
  opacity: 0.8;
  font-size: 17px;
}

.modal > * {
  margin-left: auto;
  margin-right: auto;
}
/* @media (min-width: 500px) {
  .ReactModalPortal > div {
    transform: scale(1);
  }
} */
.ReactModalPortal > div {
  background-color: rgba(0, 0, 0, 0.65) !important;
}
.ReactModalPortal > div > div {
  max-width: 395px;
}
/* @media (min-width: 370px) { */
/* } */

.modal .loading-container svg {
  width: 90px;
  height: 90px;
  margin-top: 30px;
  margin-bottom: 15px;
}
.modal.order .buttons {
  margin: unset;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.modal.order .buttons button {
  margin-left: 20px;
  margin-right: 20px;
  padding: unset;
  min-height: 42px;
  font-weight: bold;
  font-size: 20px;
}
.modal.order .buttons .outline {
  border: 2px solid rgba(0, 0, 0, 0.33);
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

/* @media (max-width: 370px) {
  .modal.order .buttons button {
    padding-right: 20px;
    padding-left: 20px;
  }
} */
@media (max-width: 325px) {
  .modal.order .buttons button {
    margin-right: 15px;
    margin-left: 15px;
    font-size: 16px;
  }
}

.modal .processing button {
  margin-top: 30px;
}

.modal-warning {
  border: 1px solid #af2a2a !important;
  background: #fdd6d6;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  width: 100%;
  padding: 15px;
}
